@import "settings";

.phone {
  @include adaptive-value('max-width', 400, 290, 1);
  display: flex;
  flex-direction: column;
  margin-inline: auto;
  aspect-ratio: .54;
  position: relative;
  z-index: 1;

  &__wrapper {
    display: grid;
    background-color: $main-white;
    padding: rem(18px);
    border-radius: rem(60px);
    box-shadow:  20px 16px 75px rgba($main-black, .25);

    & > * {
      grid-column: 1;
      grid-row: 1;
    }

    @media (hover: hover) {
      cursor: pointer;
      opacity: 1;
      transition: box-shadow 404ms ease-in-out, opacity 404ms ease-in-out;

      &:hover {
        opacity: .6;
        box-shadow:  5px 16px 75px rgba($main-black, .25);
      }
    }
  }

  &__picture {
    border-radius: rem(43px);
    overflow: hidden;
    position: relative;

    &::after {
      content: '';
      background-color: rgba(50, 50, 50, 0.4);
      position: absolute;
      border-radius: inherit;
      inset: 0;
    }
  }

  &__content {
    padding: rem(30px);
    isolation: isolate;
    align-self: flex-end;
  }
}
