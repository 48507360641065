@import "settings";

@include font-face('Montserrat', normal, 300, 'Montserrat-Light');
@include font-face('Montserrat', normal, normal, 'Montserrat-Regular');
@include font-face('Montserrat', normal, 500, 'Montserrat-Medium');
@include font-face('Montserrat', normal, 600, 'Montserrat-SemiBold');
@include font-face('Montserrat', normal, bold, 'Montserrat-Bold');
@include font-face('Montserrat', normal, 800, 'Montserrat-ExtraBold');
@include font-face('Montserrat', normal, 900, 'Montserrat-Black');

@include font-face('Montreal', italic, 600, 'Montreal-SemiBoldItalic');
@include font-face('Montreal', normal, bold, 'Montreal-Bold');

html {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}

.montreal-font {
  font-family: 'Montreal', sans-serif;
}
