@import "settings";

.count {
  @include adaptive-value('font-size', 32, 24, 1);
  width: rem(50px);
  display: grid;
  place-content: center;
  aspect-ratio: 1;
  box-shadow: inset 0 0 0 rem(1px) currentColor;
  border-radius: 50%;
  line-height: 1;
  font-weight: 700;

  @include breakpoint('xl') {
    width: rem(68px);
  }
}
