@import "settings";

.pricing-row {
  display: grid;
  align-items: center;
  gap: rem(16px);
  position: relative;
  min-height: rem(80px);
  padding-block: rem(12px);
  padding-inline: rem(20px);
  line-height: rem(16px);

  @include breakpoint('lg') {
    padding-inline: rem(40px);
  }

  &:has(svg-icon) {
    grid-template-columns: rem(30px) 1fr rem(30px);

    @include breakpoint('lg') {
      grid-template-columns: rem(30px) 1fr;
    }
  }

  &.has-left {
    grid-template-columns: rem(30px) 1fr;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(50%);
    width: 100%;
    height: rem(1px);
    background-color: theme('colors.silver');
  }

  span {
    font-size: rem(14px);
    font-weight: 500;
  }
}

.pricing-one-row {
  padding-block: rem(50px);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: rem(5px);
  text-align: center;
  max-width: rem(280px);
  margin-inline: auto;

  svg-icon {
    width: rem(44px);

    svg {
      width: inherit;
      aspect-ratio: 1;
    }
  }
}

.pricing-only {
  max-width: rem(180px);
  margin-inline: auto;
  text-align: center;
}

.pricing-two {
  flex-grow: 1;
  width: 100%;
  display: grid;
  grid-template-rows: 2fr 3fr;

  & > * {
    display: grid;
    place-content: center;
  }

  & > * + * {
    border-top: 1px solid theme('colors.silver');
  }
}

.item-or {
  position: relative;

  &::after,
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: rem(1px);
    width: rem(90px);
    background-color: theme('colors.silver');
  }

  &::after {
    right: calc(100% + #{rem(15px)});
  }

  &::before {
    left: calc(100% + #{rem(15px)});
  }
}
