.gooey-text {
  display: inline;

  &__content {
    @include adaptive-value('font-size', 16, 12, 1);
    padding: 15px;
    filter: url(#goo);
    display: inline;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
  }

  &__arrow {
    padding-top: rem(8px);
    padding-left: rem(8px);
    display: inline-flex;
    transform: translateY(8px);

    svg {
      aspect-ratio: 1;
      width: rem(30px);
      fill: transparent;
      display: inline;
    }
  }
}
