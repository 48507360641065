@import "settings";

.slider-block {
  @include adaptive-value('padding-top', 140, 80, 1);
  @include adaptive-value('padding-bottom', 110, 80, 1);
  @include adaptive-value('gap', 40, 20, 1);
  @include adaptive-value('border-radius', 90, 40, 1);
  @include adaptive-value('margin-inline', 30, 10, 1);
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: rem(60px);
  position: relative;

  @include breakpoint('md') {
    @include adaptive-value('margin-block', 30, 10, 1);
  }

  .count {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -23%);
    z-index: 5;
    top: 0;
    box-shadow: inset 0 0 0 rem(1px) currentColor, 0 0 0 15px $main-white;

    @include breakpoint('xl') {
      box-shadow: inset 0 0 0 rem(1px) currentColor;
      transform: translate(-50%, 0);
    }
  }

  .header-counter {
    width: rem(180px);
    height: rem(60px);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    top: 0;

    @include breakpoint('xl') {
      width: rem(224px);
      height: rem(84px);
      background-color: $main-white;
    }

    &__item {
      height: 100%;
      display: flex;
      position: absolute;
      width: 33%;
      aspect-ratio: 1;
      right: 11%;
      background: linear-gradient(180deg, $main-white 0, $main-white 40%, currentColor 40%, currentColor 100%);

      &::before {
        content: "";
        position: absolute;
        background-color: currentColor;
        border-radius: 50%;
        width: 122%;
        aspect-ratio: 1;
        right: -65%;
      }

      &:nth-child(1) {
        left: 11%;
        right: auto;
        transform: scaleX(-1);
      }

      @include breakpoint('xl') {
        right: 0;
        width: rem(130px);
        left: 100%;
        background: linear-gradient(147deg, $main-white 0, $main-white 50%, currentColor 50%, currentColor 100%);

        &::after {
          content: "";
          position: absolute;
          background-color: $main-white;
          border-radius: 50%;
          width: 108%;
          aspect-ratio: 1;
          left: -55%;
          bottom: 0;
        }

        &::before {
          right: -63%;
        }

        &:nth-child(1) {
          left: auto;
          right: 100%;
          transform: scaleX(-1);
        }
      }
    }
  }

  .picture {
    position: relative;

    &--left {
      @include adaptive-value('left', 40, 15, 1);
      @include adaptive-value('margin-left', 40, 15, 1);
    }

    &--right {
      @include adaptive-value('right', 40, 15, 1);
      @include adaptive-value('margin-right', 40, 15, 1);
    }

    &--full {
      @include adaptive-value('left', -40, -15, 1);
      @include adaptive-value('right', -40, -15, 1);
      width: calc(100% + #{rem(30px)});
      max-width: none;
    }
  }
}
