@import "settings";

.block-image {
  @include adaptive-value('padding-top', 60, 20, 1);
  @include adaptive-value('padding-bottom', 80, 50, 1);
  @include adaptive-value('gap', 40, 20, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 5;

  &__picture {
    margin: auto;
    position: relative;

    @include breakpoint('md') {
      max-width: rem(1250px);
      padding-inline: rem(15px);
    }

    app-info-item {
      position: absolute;
      z-index: 1;
    }

    .one {
      left: 15%;
      top: 18%;
    }

    .two {
      bottom: 28%;
      right: 23%;
    }
  }
}
