@import "settings";

html {
  font-size: $global-font-size;
  color: $main-white;
  font-weight: 600;
}

.container {
  padding-inline: rem(16px);
  width: 100%;

  @include breakpoint('xlg') {
    padding-inline: calc((50vw - (#{$container-max-width} / 2)) + 16px);
  }
}

.full-page-section {
  display: flex;
  flex-direction: column;

  @include breakpoint('xxl') {
    min-height: 100vh;
  }

  @media (max-height: 800px) and (min-width: 992px){
    &:not(.banner) {
      padding-block: rem(80px);
    }
  }
}

.text-h2 {
  @include adaptive-value('font-size', 28, 18, 1);
}

.lock-scroll {
  overflow-y: hidden;
}

.blogs-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
  gap: rem(20px);
}

.pricing-wrapper {
  @include adaptive-value('gap', 80, 50, 1);
  display: grid;
}

pre {
  background: #efefef;
  padding: rem(10px);
  font-size: rem(14px);
}

.block-shadow {
  box-shadow: rem(10px) rem(10px) rem(62px) rgba(0, 0, 0, 0.45);
}
