$main-white: #ffffff;
$main-black: #000000;
$grey-dark: #1D1D1D;
$main-gray: #6F6F6F;
$yellow: #FDD835;
$silver: #C4C4C4;
$gallery: #F0F0F0;
$tundora: #484848;
$dark-gray: #343434;
$pink: #DEBBB6;
$silver-chalice: #9D9D9D;
