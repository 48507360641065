@import "settings";

.fake-accordion {
  font-size: rem(9px);
  padding: rem(25px) rem(90px) rem(25px) rem(50px);
  border-radius: rem(20px);
  background-color: $dark-gray;
  filter: drop-shadow(-28px 4px 20px rgba($main-black, 0.25));

  &__icon {
    color: $dark-gray;
    position: absolute;
    top: rem(25px);
    right: rem(25px);
    width: rem(18px);
    aspect-ratio: 1;
    display: grid;
    place-content: center;
    border-radius: 50%;
    background-color: $main-white;
    transform: rotate(90deg);
  }
}
