:root {
  --animate-duration: 1s;
  --animate-delay: 1s;
  --animate-repeat: 1;
}

[appinview] {
  animation-duration: var(--animate-duration);
  animation-fill-mode: both;
  opacity: 0;

  &[class*="animate_"] {
    opacity: 1;
  }
}

.arc {
  svg {
    fill: transparent;
    stroke-dasharray: 150;
    stroke-dashoffset: 150;
  }

  &.start {
    opacity: 1;

    svg {
      animation-name: strokeLine;
      animation-delay: 1500ms;
      animation-duration: 1400ms;
      animation-fill-mode: forwards;
      animation-timing-function: ease-in-out;
    }
  }
}

.wave {
  svg {
    fill: transparent;
    stroke-dasharray: 400;
    stroke-dashoffset: 400;
  }

  &.start {
    opacity: 1;

    svg {
      animation-name: strokeLine;
      animation-delay: 1500ms;
      animation-duration: 1400ms;
      animation-fill-mode: forwards;
      animation-timing-function: ease-in-out;
    }
  }
}

.animate {
  animation-timing-function: ease-in-out;

  &__fadeInLeftBlur {
    animation-name: fadeInLeftBlur;
  }

  &__fadeInRightBlur {
    animation-name: fadeInRightBlur;
  }

  &__fadeInUpBlur {
    animation-name: fadeInUpBlur;
  }

  &__fadeInRight {
    animation-name: fadeInRight;
  }

  &__fadeInLeft {
    animation-name: fadeInLeft;
  }

  &__fadeIn {
    animation-name: fadeIn;
  }

  &__fadeInDown {
    animation-name: fadeInDown;
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -60%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(20%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-20%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes strokeLine {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes fadeInInfo {
  0% {
    opacity: 0;
    filter: blur(3px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    filter: blur(0);
    transform: translateY(0);
  }
}

@keyframes scaleInInfo {
  0% {
    opacity: 0;
    filter: blur(3px);
    transform: scale(0);
  }
  100% {
    opacity: 1;
    filter: blur(0);
    transform: scale(1);
  }
}

@keyframes showLink {
  0% {
    opacity: .5;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes tilt-n-move-shaking {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(5px, 0);
  }
  50% {
    transform: translate(0, 0);
  }
  75% {
    transform: translate(-5px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes fadeInLeftBlur {
  0% {
    opacity: 0;
    filter: blur(4px);
    transform: translateX(30px);
  }
  100% {
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
  }
}

@keyframes fadeInRightBlur {
  0% {
    opacity: 0;
    filter: blur(4px);
    transform: translateX(-30px);
  }
  100% {
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
  }
}

@keyframes fadeInUpBlur {
  0% {
    opacity: 0;
    filter: blur(4px);
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    filter: blur(0);
    transform: translateY(0);
  }
}

@keyframes circleApplyFrom {
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 700;
  }
}

@keyframes cartKanban {
  0% {
    transform: translate(0, 0) rotate(0);
    box-shadow: 42px 19px 68px transparent;
  }
  50% {
    transform: translate(-80%, -50%) rotate(0);
    box-shadow: 42px 19px 68px transparent;
  }
  60% {
    transform: translate(-80%, -50%) rotate(-10deg);
  }
  65% {
    transform: translate(-80%, -50%) rotate(-10deg);
    box-shadow: 42px 19px 68px rgba(254, 228, 114, 0.3);
  }
  80% {
    transform: translate(-80%, -50%) rotate(-10deg);
    box-shadow: 42px 19px 68px rgba(254, 228, 114, 0.3);
  }
  100% {
    transform: translate(0, 0) rotate(0);
    box-shadow: 42px 19px 68px transparent;
  }
}

@keyframes arrowKanban {
  0%, 50% {
    opacity: 0;
  }
  65% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  85%, 100% {
    opacity: 0;
  }
}
