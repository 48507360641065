@import "settings";

.block-opened {
  padding: rem(20px);
  box-shadow: inset 0 0 0 rem(1px) $main-white;
  border-radius: rem(10px);
  display: grid;
  grid-template-columns: rem(10px) 1fr;
  grid-template-rows: auto 1fr;
  gap: rem(10px);
  align-items: center;

  &__name {
    font-size: rem(9px);
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    gap: rem(6px);
    grid-column-start: 2;
  }
}

.label-value {
  font-size: rem(10px);
  padding-inline: rem(15px);
  padding-block: rem(5px);
  border-radius: 50vmax;
}
