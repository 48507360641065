@import "settings";

.story-slide {
  &.swiper-slide-active {
    z-index: 2;

    .story-item {
      width: 100%;
      transition: width 600ms linear;

      &::before {
        opacity: 0;
        transition: opacity 600ms linear;
      }

      &::after {
        width: rem(60px);
      }

      &__main {
        opacity: 1;
      }

      &__content {
        display: block;
        font-size: 0;
        animation-name: showContent;
        animation-delay: 800ms;
        animation-duration: 300ms;
        animation-fill-mode: forwards;
      }

      &__social {
        background-color: $main-black;
      }
    }
  }

  &.swiper-slide-prev,
  &.swiper-slide-next {
    z-index: -1;
    transition: transform 400ms linear;
  }

  app-story-item {
    display: flex;
    justify-content: center;
  }

  .story-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: rem(80px) rem(35px) rem(60px) rem(35px);
    border-radius: rem(35px);
    z-index: 1;
    gap: rem(25px);
    color: $main-white;
    position: relative;
    overflow: hidden;
    font-size: rem(12px);
    width: 50%;
    height: 300px;

    @include breakpoint('lg') {
      padding: rem(150px) rem(50px) rem(70px);
      font-size: rem(16px);
      height: 500px;
    }

    @include breakpoint('xlg') {
      gap: rem(40px);
      font-size: rem(18px);
      height: 700px;
    }

    img {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -1;
    }

    &::before {
      content: '';
      position: absolute;
      inset: 0;
      background-color: rgba($dark-gray, 0.9);
      transition: opacity 404ms linear;
      opacity: 1;
    }

    &::after {
      content: '';
      position: absolute;
      top: rem(30px);
      left: rem(35px);
      width: rem(25px);
      height: rem(9px);
      border-radius: 100vmax;
      background: $main-white;
      transition: width 404ms 600ms;
      z-index: 2;

      @include breakpoint('md') {
        width: rem(20px);
        height: rem(20px);
        left: rem(50px);
      }
    }

    &__main {
      display: flex;
      flex-grow: 1;
      transition: opacity 404ms;
    }

    &__content {
      display: none;
      opacity: 0;
      font-size: 0;
    }

    &__author {
      display: none;
      position: relative;
      z-index: 1;

      @include breakpoint('md') {
        display: inline-flex;
      }

    }

    &__social {
      display: flex;
      gap: rem(5px);
      align-items: center;
      padding: rem(5px);
      border-radius: rem(8px);
      background-color: $dark-gray;
      z-index: 1;
      position: relative;
      transition: background-color 600ms linear;

      @include breakpoint('md') {
        padding: rem(15px);
        gap: rem(15px);
      }
    }

    .social-item {
      border-radius: rem(5px);
      display: flex;

      @media (hover: hover) {
        cursor: pointer;
        box-shadow: inset 0 0 0 0 ,0 0 0 0 $main-white;
        transition: box-shadow 404ms;

        &:hover {
          box-shadow: inset 0 0 0 rem(2px) ,0 0 0 rem(4px) $main-white;
        }
      }
    }
  }
}

@keyframes test {
  0% {
    transform: translateX(-44%);
  }
  100% {
    transform: translateX(-22%);
  }
}

@keyframes showContent {
  0% {
    opacity: 0;
    font-size: 0;
  }
  15% {
    opacity: 0;
    font-size: inherit;
  }
  100% {
    font-size: inherit;
    opacity: 1;
  }
}
