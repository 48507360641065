@import "settings";

.socials {
  @include adaptive-value('padding-block', 15, 10, 1);
  @include adaptive-value('padding-inline', 20, 15, 1);
  display: flex;
  align-items: center;
  border-radius: 50vmax;

  &__item {
    --clr-hover: var(--tw-ring-color);

    @include adaptive-value('width', 60, 45, 1);
    @include adaptive-value('border-radius', 17, 10, 1);
    aspect-ratio: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;

    &::after {
      content: "";
      position: absolute;
      inset: 0;
      border-radius: inherit;
      background-color: var(--clr-hover);
      opacity: 0;
      transform: scale(0);
      transition-property: opacity, transform;
      transition-duration: .15s;
      transition-timing-function: cubic-bezier(.4, 0, .2, 1);
      z-index: -1;
    }

    @media (hover: hover) {
      cursor: pointer;

      &:hover {
        &::after {
          opacity: 1;
          transform: scale(1);
        }
      }
    }

    svg-icon {
      width: 60%;
    }

    svg {
      display: flex;
      width: 100%;
      aspect-ratio: 1;
    }
  }
}
