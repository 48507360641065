@import "settings";

.swiper-pagination {
  --swiper-pagination-bottom: auto;
  --swiper-pagination-top: auto;
  --swiper-pagination-bullet-horizontal-gap: 0;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: rem(16px);
  gap: rem(12px);
  width: auto;

  .swiper-pagination-bullet {
    width: auto;
    height: auto;
    background-color: transparent;

    &.swiper-pagination-bullet-active {
      font-weight: 700;

      &::after {
        opacity: .2;
      }
    }

    & + .swiper-pagination-bullet {
      position: relative;

      &::after {
        content: '/';
        position: absolute;
        top: 0;
        left: -5px;
        transform: translateX(-50%);
        font-weight: 400;
      }
    }
  }
}

.dots-pagination {
  --swiper-pagination-bottom: auto;
  --swiper-pagination-top: auto;
  --swiper-pagination-bullet-horizontal-gap: 0;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  gap: rem(10px);

  .swiper-pagination-bullet {
    background-color: $dark-gray
  }
}
