@import "settings";

.summary {
  @include adaptive-value('padding-inline', 60, 20, 1);
  @include adaptive-value('padding-block', 50, 30, 1);
  box-shadow: rem(-26px) rem(9px) rem(85px) rgba($main-black, 0.1);
  display: grid;
  gap: rem(10px);
  border-radius: rem(65px);

  p {
    font-size: rem(15px);
  }
}
