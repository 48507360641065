@import "settings";

.info-card {
  text-align: center;
  display: grid;
  gap: rem(10px);
  border-radius: rem(40px);
  min-height: clamp(#{rem(280px)}, 35vh, #{rem(340px)});
  grid-template-rows: 65% auto;
  place-items: center;
  padding: rem(25px);

  svg-icon {
    width: rem(42px);
    aspect-ratio: 1;
  }

  &__text {
    place-self: normal;
    font-size: rem(13px);
    padding-left: 15%;
    padding-right: 15%;
  }
}
