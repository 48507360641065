.line {
  svg {
    stroke-dasharray: 2400;
    stroke-dashoffset: 2400;
  }

  &.start {
    opacity: 1;

    svg {
      animation-name: strokeLine;
      animation-delay: 400ms;
      animation-duration: 1400ms;
      animation-fill-mode: forwards;
      animation-timing-function: ease-in-out;
    }
  }
}

.arrow-spiral {
  svg {
    fill: transparent;

    [id="spiral-line"] {
      stroke-dasharray: 1000;
      stroke-dashoffset: 1000;
    }

    [id="spiral-arrow"] {
      stroke-dasharray: 200;
      stroke-dashoffset: 200;
    }
  }
}
