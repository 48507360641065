@import "settings";

.product-item {
  box-shadow: inset 0 0 0 rem(1px) $main-white;
  border-radius: rem(20px);
  padding: rem(25px);
  display: flex;
  flex-direction: column;
  gap: rem(15px);
  font-size: rem(7px);

  &__logo {
    color: $main-black;
    display: grid;
    grid-template-columns: rem(15px) rem(40px);
    gap: rem(4px);
    align-items: center;
    padding: rem(8px) rem(10px);
    border-radius: 50vmax;
    background-color: $main-white;
    align-self: flex-start;
  }

  button {
    font-size: rem(14px);
    border-radius: 50vmax;
    padding-block: rem(15px);
    display: grid;
    place-content: center;
  }
}
