@import "settings";

.hover-weight {
  transition: text-shadow 404ms, opacity 404ms;
  user-select: none;

  &.active {
    text-shadow: rem(-.4px) 0 currentColor, rem(.4px) 0 currentColor;
  }

  &:hover {
    text-shadow: rem(-.4px) 0 currentColor, rem(.4px) 0 currentColor;
    opacity: .6;
  }
}
