@import "settings";

.banner-company {
  @include adaptive-value('gap', 60, 40, 1);
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__content {
    display: flex;
    flex-direction: column;
    gap: rem(25px);
    text-align: center;
    position: relative;
    padding-top: rem(160px);

    @include breakpoint('xxl') {
      padding-top: 0;
    }

    .arrow-spiral {
      position: absolute;
      top: rem(10px);
      left: 0;
      transform: rotate(-45deg);
      width: rem(150px);

      @include breakpoint('md') {
        left: 50%;
        transform: rotate(-45deg) translate(-50%, -50%);
      }

      @include breakpoint('xxl') {
        width: rem(180px);
        left: 0;
        transform: rotate(-5deg);
        bottom: 70%;
        top: rem(-140px);
      }

      &.start {
        opacity: 1;

        [id="spiral-line"],
        [id="spiral-arrow"] {
          animation-name: strokeLine;
          animation-duration: 1200ms;
          animation-fill-mode: forwards;
          animation-timing-function: ease-in-out;
        }

        [id="spiral-line"] {
          animation-delay: 400ms;
        }

        [id="spiral-arrow"] {
          animation-delay: 1400ms;
        }
      }
    }

    .arc {
      position: absolute;
      top: 100%;
      right: 0;
      transform: rotate(15deg);
    }

    p {
      @include adaptive-value('font-size', 20, 12, 1);
    }

    h1 {
      @include adaptive-value('font-size', 95, 26, 1);
      line-height: 1.3;
    }
  }

  &__action {
    @include adaptive-value('gap', 30, 8, 1);
    display: flex;
    flex-direction: column;

    @include breakpoint('md') {
      flex-direction: row;
    }
  }
}
