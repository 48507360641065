@import "settings";

.banner {
  @include adaptive-value('border-radius', 105, 40, 1);
  padding-block: rem(20px);
  padding-inline: rem(30px);
  margin: rem(10px);
  gap: rem(30px);
  width: calc(100% - #{rem(20px)});

  @include breakpoint('lg') {
    margin: 0;
    width: 100%;
  }

  @include breakpoint('xl') {
    padding-inline: rem(50px);
    padding-bottom: rem(70px);
  }

  @include breakpoint('xxl') {
    @include adaptive-value('padding-inline', 250, 30, 1);
  }
}
