@import "settings";

.management-card {
  position: absolute;
  width: 22.8%;
  left: 51%;
  top: 17.3%;
  border-radius: rem(10px);
  animation-name: cartKanban;
  animation-duration: 5000ms;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  svg-icon {
    position: absolute;
    top: 102%;
    right: 10%;
    animation-name: arrowKanban;
    animation-duration: 5000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
}
