@import "settings";

.banner-talent {
  margin: auto;
  padding-bottom: rem(70px);

  @include breakpoint('lg') {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__content {
    @include adaptive-value('gap', 80, 20, 1);
    padding-top: rem(100px);
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      @include adaptive-value('font-size', 95, 26, 1);
      text-align: center;
      line-height: 1.3;
    }
  }

  &__sub-title {
    @include adaptive-value('font-size', 20, 12, 1);
  }

  &__action {
    @include adaptive-value('margin-top', 50, 30, 1);
  }

  .arrow-spiral {
    position: absolute;
    z-index: -1;
    transform: rotate(25deg);
    width: rem(170px);
    top: rem(120px);
    left: rem(-30px);

    @include breakpoint('lg') {
      left: auto;
      right: calc(100% + #{rem(20px)});
      transform: rotate(-5deg);
      top: rem(-20px);
    }

    &.start {
      opacity: 1;

      [id="spiral-line"],
      [id="spiral-arrow"] {
        animation-name: strokeLine;
        animation-duration: 1200ms;
        animation-fill-mode: forwards;
        animation-timing-function: ease-in-out;
      }

      [id="spiral-line"] {
        animation-delay: 400ms;
      }

      [id="spiral-arrow"] {
        animation-delay: 1400ms;
      }
    }
  }

  .wave {
    position: absolute;
    bottom: rem(30px);
    right: rem(10px);
    width: rem(100px);

    @include breakpoint('lg') {
      right: auto;
      left: calc(100% + #{rem(30px)});
    }
  }
}
