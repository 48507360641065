@import "animation";
@import "settings";
@import "swiper/swiper.min.css";

@import "atoms/count";
@import "atoms/swiper-pagination";
@import "atoms/info-item";
@import "atoms/gooey-text";
@import "atoms/swiper-navigation";
@import "atoms/button";
@import "atoms/management-card";
@import "atoms/animate-item";
@import "atoms/info-slider";
@import "atoms/summary";
@import "atoms/cursore";

@import "block/logos";
@import "block/info-card";
@import "block/story-slide";
@import "block/block-opened";
@import "block/swiper-action";
@import "block/slider-block";
@import "block/banner-company";
@import "block/banner-talent";
@import "block/home-banner";
@import "block/branding-item";
@import "block/product-item";
@import "block/block-image";
@import "block/home-slider-item";

@import "molecules/socials";
@import "molecules/phone";
@import "molecules/support-section";

@import "styles/settings";
@import "styles/base";
@import "styles/fonts";

@import "items/hover-weight";
@import "items/select";
@import "items/fake-accordion";
@import "items/pricing-rows";

