@import "settings";

.swiper-navigation {
  --sn-box-shadow: #{$main-white};
  --sn-color: #{$main-white};
  --sn-color-icon: #{$main-white};
  --sn-color-icon-hover: #{$main-white};

  &--gray {
    --sn-box-shadow: #{$dark-gray};
    --sn-color: #{$dark-gray};
    --sn-color-icon: #{$dark-gray};
    --sn-color-icon-hover: #{$main-white};
  }

  width: rem(32px);
  height: rem(32px);
  aspect-ratio: 1;
  box-shadow: inset 0 0 0 rem(1px) var(--sn-box-shadow);
  border-radius: 50%;
  display: flex;
  color: var(--sn-color);
  transition: box-shadow 404ms ease-in-out, color 404ms ease-in-out;
  position: static;
  margin-top: 0;

  @media (hover: hover) {
    &:hover {
      color: $main-black;
      box-shadow: inset 0 0 0 rem(16px) var(--sn-box-shadow);

      svg-icon {
        svg {
          color: var(--sn-color-icon-hover);
        }
      }
    }

    svg-icon {
      svg {
        color: var(--sn-color-icon);
        transition: color 404ms ease-in-out;
      }
    }

    &.swiper-button-next {
      svg-icon {
        transform: scaleX(-1);
      }
    }
  }
}
