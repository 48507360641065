@import "settings";

swiper-slide.branding-item {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: visible;

  .animate-label {
    @include breakpoint('lg') {
      position: absolute;
      opacity: 0;
      z-index: 5;
    }
  }

  .fake-accordion,
  .arrow-spiral,
  .products,
  .block-opened {
    @include breakpoint('lg') {
      position: absolute;
      opacity: 0;
    }
  }

  &.swiper-slide-active {
    .animate-label,
    .fake-accordion,
    .products,
    .arrow-spiral,
    .block-opened {
      animation-duration: 700ms;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
    }

    .arrow-spiral {
      opacity: 1;

      svg {
        [id="spiral-line"],
        [id="spiral-arrow"] {
          animation-name: strokeLine;
          animation-duration: 1200ms;
          animation-fill-mode: forwards;
          animation-timing-function: ease-in-out;
        }
      }
    }

    .animate-label {
      animation-name: fadeInInfo;
    }

    .fake-accordion,
    .products,
    .block-opened {
      animation-name: scaleInInfo;
    }
  }

  img {
    max-height: 100%;
    border-radius: rem(40px);
    box-shadow: rem(-48px) rem(35px) rem(184px) rgba($main-black, .51);
  }
}

.branding-one {
  .one {
    top: 10%;
    right: -20%;
    animation-delay: 700ms;
  }

  .fake-accordion {
    top: 22%;
    right: -20%;
    animation-delay: 200ms;
    z-index: 10;
  }

  .two {
    left: 15%;
    bottom: 7%;
    animation-delay: 2100ms;
  }

  .products {
    bottom: 20%;
    left: -30%;
    animation-delay: 1400ms;
  }

  .arrow-spiral {
    opacity: 1;
    width: 180px;
    transform: scaleX(-1) rotate(50deg);
    top: 20%;

    [id="spiral-line"] {
      animation-delay: 1300ms;
    }

    [id="spiral-arrow"] {
      animation-delay: 2000ms;
    }
  }
}

.branding-two {
  .one {
    top: 10%;
    left: -25%;
    animation-delay: 400ms;
  }

  .two {
    top: 35%;
    left: -5%;
    animation-delay: 700ms;
  }

  .three {
    top: 23%;
    left: 15%;
    animation-delay: 1000ms;
  }

  .four {
    top: 50%;
    right: -10%;
    animation-delay: 1600ms;
    z-index: 10;
  }

  .five {
    top: 70%;
    right: -15%;
    animation-delay: 2100ms;
  }

  .arrow-spiral {
    opacity: 1;
    width: 180px;
    transform: rotate(60deg);
    top: 20%;
    left: 45%;

    [id="spiral-line"] {
      animation-delay: 1300ms;
    }

    [id="spiral-arrow"] {
      animation-delay: 2000ms;
    }
  }
}
