@import "settings";

.btn {
  @include adaptive-value('font-size', 22, 14, 1);
  display: inline-block;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 0;
  font-size: rem(14px);
  padding: rem(16px) rem(60px);
  border-radius: 100vmax;
  background: $main-white;
  transition: filter 404ms;

  @include breakpoint('xxl') {
    padding: rem(30px) rem(60px);
  }

  &--small {
    font-size: rem(14px);
    padding-block: rem(15px);
    padding-inline: rem(25px);
  }

  &__link {
    padding: 0;
    min-width: auto;
    border-radius: 0;
    background: transparent;
    color: currentColor;

    &--underline {
      text-decoration: underline;
    }
  }

  @media (hover: hover) {
    cursor: pointer;

    &:hover {
      filter: invert(0.2);
    }
  }
}

.action-btn {
  @include adaptive-value('font-size', 22, 16, 1);
  @include adaptive-value('padding-block', 46, 30, 1);
  @include adaptive-value('padding-inline', 46, 30, 1);
  font-weight: 700;
  border-radius: 50vmax;
  display: inline-flex;
  text-align: center;
  justify-content: center;

  &--border {
    box-shadow: inset 0 0 0 rem(2px) currentColor;
  }

  &--tilt {
    animation: tilt-n-move-shaking 700ms cubic-bezier(.36, .07, .19, .97) infinite;

    &:hover {
      animation-play-state: paused;
    }
  }

  @media (hover: hover) {
    transition: opacity 404ms ease-in-out;

    &:hover {
      opacity: .6;
    }
  }
}

.mobile-btn {
  width: rem(30px);

  div,
  &::after,
  &::before {
    background: currentColor;
    content: '';
    display: block;
    height: rem(3px);
    border-radius: rem(2px);
    margin: rem(4px) 0;
    transition: transform 500ms;
    transform-origin: center;
  }

  &.btn-opened {
    &:before {
      transform: translateY(7px) rotate(135deg);
    }

    &:after {
      transform: translateY(-7px) rotate(-135deg);
    }

    div {
      transform: scale(0);
    }
  }
}

.mobile-btn-registration {
  margin-left: auto;
  border-radius: rem(30px);
  border: none;
  padding: rem(10px) rem(20px);
  background-color: $main-black;
  color: $main-white;
  font-weight: 800;
  font-size: rem(14px);

  @include breakpoint('lg') {
    display: none;
  }
}
