@import "settings";

swiper-slide.slider-item {
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1.8;
  position: relative;
  overflow: visible;

  .animate-label {
    @include breakpoint('lg') {
      position: absolute;
      opacity: 0;
      z-index: 5;
    }
  }

  .animate-content,
  .status-block {
    @include breakpoint('lg') {
      position: absolute;
      opacity: 0;
      z-index: 1;
    }
  }

  &.swiper-slide-active {
    .animate-label,
    .animate-content,
    .status-block {
      animation-duration: 700ms;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
    }

    .animate-label,
    .status-block {
      animation-name: fadeInInfo;
    }

    .animate-content {
      animation-name: scaleInInfo;
    }
  }
}

.personal-one {
  .one {
    top: 2%;
    left: -7%;
    animation-delay: 500ms;
  }

  .two {
    right: -12%;
    top: 58%;
    animation-delay: 1100ms;
  }

  .status-block {
    animation-delay: 700ms;
    right: 12%;
    top: 25%;
  }
}

.personal-two {
  .one {
    top: 32%;
    left: 20%;
    animation-delay: 1000ms;
  }

  .two {
    right: 10%;
    animation-delay: 1200ms;
    top: 60%;
  }

  .info-slider {
    top: 40%;
    left: 1%;
    animation-delay: 600ms;
  }

  .summary {
    bottom: 1%;
    right: -3%;
    animation-delay: 700ms;
  }
}

.personal-three {
  .one {
    top: 9%;
    left: 15%;
    animation-delay: 900ms;
  }

  .two {
    right: 16%;
    animation-delay: 1100ms;
    top: 29%;
  }

  .info-slider {
    top: 20%;
    left: -5%;
    animation-delay: 600ms;
  }
}

.best-one {
  .one {
    top: 25%;
    left: 18%;
    animation-delay: 600ms;
  }
}

.best-two {
  .one {
    top: 0;
    left: 70%;
    animation-delay: 900ms;
    z-index: 8;
  }

  .two {
    width: 50%;
    left: 25%;
    top: 10%;
    z-index: 6;
    animation-delay: 600ms;
  }

  .three {
    animation-delay: 1200ms;
    max-width: 100%;
    height: 110%;
    display: flex;
    flex-direction: column;
    top: -6%;
    left: 0;
  }
}

.the-best-mobile {
  position: relative;
  display: flex;
  flex-direction: column;
  aspect-ratio: 1;

  .background {
    position: absolute;
    width: 200%;
    max-width: none;
    left: 0;
    top: 0;
  }

  .search {
    position: absolute;
    right: -10%;
    display: flex;
    width: 100%;
    aspect-ratio: 1;
    z-index: 2;
  }

  .filter {
    position: absolute;
    right: -10%;
    display: flex;
    width: 80%;
    z-index: 1;
    top: 7%;
  }
}
