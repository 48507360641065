@import "settings";

.support-section {
  app-info-item {
    @include breakpoint('lg') {
      position: absolute;
      min-width: rem(240px);

      &:nth-child(1) {
        right: calc(100% + #{rem(70px)});
        animation-delay: 200ms;
        top: 10%;
      }

      &:nth-child(2) {
        right: calc(100% + #{rem(70px)});
        animation-delay: 600ms;
        top: 70%;
      }

      &:nth-child(3) {
        left: calc(100% + #{rem(70px)});
        animation-delay: 1000ms;
        top: 50%;
      }
    }

    @include breakpoint('xl') {
      min-width: rem(290px);
    }
  }

  .arc {
    @include breakpoint('lg') {
      position: absolute;
      z-index: 1;
      width: rem(100px);

      &.one {
        right: calc(100% + #{rem(30px)});
        animation-delay: 300ms;
        top: 50%;
      }

      &.two {
        left: calc(100% + #{rem(40px)});
        animation-delay: 700ms;
        top: 70%;
      }
    }
  }
}
