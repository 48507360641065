@import "settings";

.swiper-action {
  display: grid;
  grid-template-columns: repeat(3, auto);
  gap: rem(20px);
  justify-content: center;
  align-items: center;
  position: absolute;
  top: calc(100% + #{rem(10px)});
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;

  &--one {
    grid-template-columns: auto;
  }
}
