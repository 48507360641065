@import "settings";

.logos {
  display: flex;
  align-items: center;
  gap: rem(18px);

  svg-icon {
    width: rem(34px);
    aspect-ratio: 1;
    display: flex;
  }

  &--active {
    animation: shake 3s cubic-bezier(.36,.07,.19,.97) infinite;
  }
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, -50%, 0);
  }

  20%, 80% {
    transform: translate3d(2px, -50%, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, -50%, 0);
  }

  40%, 60% {
    transform: translate3d(4px, -50%, 0);
  }
}

